import {DateTime} from 'luxon'
import axios from 'axios'
import {usePage} from '@inertiajs/inertia-vue3'

Array.prototype.joinProp = function(prop, delim) {
  return this.map((e) => e[prop]).join(delim ?? ', ');
}

Array.prototype.sortProp = function(prop, asc) {

  if (this.length === 0) {
    return this
  }

  asc ??= true
  let sortFunc

  if (typeof this[0][prop] === "string") {
    if (asc) {
      sortFunc = (a,b) => (a[prop] > b[prop]) ? 1 : ((b[prop] > a[prop]) ? -1 : 0)
    } else {
      sortFunc = (a,b) => (b[prop] > a[prop]) ? 1 : ((a[prop] > b[prop]) ? -1 : 0)
    }
  } else {
    if (asc) {
      sortFunc = ((a,b) => a[prop] - b[prop]);
    } else {
      sortFunc = ((a,b) => b[prop] - a[prop]);
    }
  }

  return this.sort(sortFunc)
}

export function scrollToId(id, offset) {

  const element = document.getElementById(id);
  const modalPageContentElement = document.getElementById('modal-page-content');
  const scrollElement = modalPageContentElement ?? window

  if (element) {

    const headerOffset = offset ?? 80;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;

    scrollElement.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    })

    element.focus()
  }
}

export function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;

    while (k--) {
      arr.push(undefined)
    }

  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])

}

export function urlParam(name, defaultValue) {
  return new URLSearchParams(window.location.search).get(name) ?? (defaultValue ?? null)
}

export function ucfirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function uc(str) {
  return str.toUpperCase()
}

export function dateFormat(date, fallback) {

  if (undefined === date) {
    return DateTime.now().toFormat('dd.MM.yyyy')
  }

  let dt = parseDate(date)

  if (dt === null || ! dt.isValid) {
    return fallback ?? ''
  }

  return dt.toFormat('dd.MM.yyyy')
}

export function datetimeFormat(date, fallback) {

  if (undefined === date) {
    return new DateTime().toFormat('dd.MM.yyyy HH:mm')
  }

  let dt = parseDate(date)

  if (dt === null || ! dt.isValid) {
    return fallback ?? ''
  }

  return dt.toFormat('dd.MM.yyyy HH:mm');
}
export function timeFormat(date, fallback) {

  let dt = parseDate(date)

  if (! dt.isValid) {
    return fallback ?? ''
  }

  return dt.toFormat('HH:mm');
}

export function parseDate(date) {

  let dt = null

  if (date && date.isValid) {
    dt = date
  }

  if (typeof date === 'string') {
    dt = DateTime.fromISO(date)

    if (dt === null || ! dt.isValid) {
      dt = DateTime.fromSQL(date)
    }

  }

  return dt
}

export function setObjVal(obj, val) {
  return Object.keys(obj).forEach(k => obj[k] = val);
}

export async function download(url, parameters, method) {

  method ??= 'GET'

  return new Promise((resolve, reject) => {
    axios.request({
      method: method ?? 'GET',
      url: url,
      params: (method === 'GET' ? parameters : null),
      data: (method !== 'GET' ? parameters : null),
      responseType: 'blob',
    })
      .then(
        (response) => {
          const filename =  response.headers['x-filename'] ?? 'export.txt'
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename )
          document.body.appendChild(link)
          link.click()

          return resolve(true)
        },
        (error) => {
          return reject(error)
        }
      )
  })

}

export function phoneLink(phone) {

  if (! phone) return

  return 'tel:' + (! phone.startsWith('+41') ? '+41' : '') + phone.substring(1).replaceAll(' ', '')
}

export async function copyToClipboard(url) {

  return new Promise(async function(resolve, reject) {

    const loadData = async () => {
      const response = await axios.get(url)
      return await response.data;
    }

    // Safari.
    if (navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1) {

      navigator.clipboard.write([new ClipboardItem({"text/plain": loadData()})])
        .then(() => resolve())
        .catch((error) => reject(error)  )

    // Firefox & Chrome.
    } else {

      const data = await loadData()

      navigator.clipboard.writeText(await data).then(
        () => resolve(),
        (error) => reject(error));
    }

  });

}

export function highlight(words, query) {

  if (! query) {
    return words
  }

  const iQuery = new RegExp(query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), "ig");

  return words.toString().replace(iQuery, function(matchedTxt,a,b){
    return ('<span class=\'highlight\'>' + matchedTxt + '</span>');
  });

}

export function chf(amount) {
  return new Intl.NumberFormat('de-CH', {minimumFractionDigits: 2}).format(amount)
}

//https://wunnle.com/dynamic-text-color-based-on-background
export function textColorForBackground(bgColor) {

  if (null === bgColor || undefined === bgColor) {
    return '#ffffff'
  }

  const getRGB = (c) => {
    return parseInt(c, 16) || c
  }

  const getsRGB = (c) => {
    return getRGB(c) / 255 <= 0.03928
      ? getRGB(c) / 255 / 12.92
      : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4)
  }

  const getLuminance = (hexColor) => {

    return (
      0.2126 * getsRGB(hexColor.substring(1, 2)) +
      0.7152 * getsRGB(hexColor.substring(3, 4)) +
      0.0722 * getsRGB(hexColor.substring(5, 6))
    )
  }

  const getContrast = (f, b) => {
    const L1 = getLuminance(f)
    const L2 = getLuminance(b)

    return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05)
  }

  const whiteContrast = getContrast(bgColor, '#ffffff')
  const blackContrast = getContrast(bgColor, '#000000')

  return whiteContrast > blackContrast ? '#ffffff' : '#000000'
}
