<template>
  <div class="">
    <Button type="button"
            class="p-button-text p-button-rounded p-button-secondary"
            :icon="icon"
            @click="toggle"
            aria-haspopup="true"
            aria-controls="options_menu"/>

    <Menu id="options_menu" ref="menu" :model="options" :popup="true" />

  </div>

</template>

<script setup>

import Button from 'primevue/button'
import Menu from 'primevue/menu'

const props = defineProps({
  options: {type: Array, required: true},
  icon: {type: String, default: 'pi pi-ellipsis-v'}
})

const menu = $ref()

function toggle(event) {
  menu.toggle(event);
}

function label(item) {
  return (typeof item.label === 'function' ? item.label() : item.label);
}
</script>

