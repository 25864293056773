export default class {

  constructor(user) {
    this.user = user

    if (this.user) {
      Object.keys(this.user).forEach((prop) => this[prop] = this.user[prop])
    }
  }


  isAdmin() {
    return this.user?.is_admin
  }
  isAccountant() {
    return this.user?.is_accountant
  }

  isTherapist() {
    return this.user?.is_therapist
  }

}
