<template>
  <Dialog id="search-modal"
          v-model:visible="_visible"
          @hide="emit('update:modelValue', false)"
          position="top"
          :modal="true"
          :show-header="false"
          :dismissableMask="true"
          :breakpoints="{'960px': '70vw', '640px': '80vw'}"
          content-class="search-modal-content"
          :draggable="false"
          :style="{width: '55vw', 'max-height': '70vh'}">

    <div class="flex align-items-center justify-content-between" style="height: 3rem">
      <div class="p-input-icon-left w-full">
        <i class="pi search-icon icon pi-search" />
        <InputText placeholder="Suchen ..."
                   v-model="_term"
                   ref="input"
                   autofocus
                   class="p-inputtext-lg w-full border-none focus:border focus:shadow-none" />
      </div>


      <div class="flex align-items-center">

        <div v-if="! _searching && _term.length > 2 && ! _results.length" class="text-sm text-gray-500 nowrap mr-3">
          — Keine Resultate
        </div>

        <Button v-if="_term.length"
                class="p-button-text p-button-rounded p-button-secondary"
                icon="pi pi-times"
                @click="_term = ''" />

      </div>

    </div>

    <div v-if="_results.length" style="height:63vh" class="pb-4">
      <DataTable :value="_results"
                 sortField="name"
                 :sortOrder="1"
                 :rowHover="true"
                 :paginator="true"
                 :rows="50"
                 :always-show-paginator="false"
                 @row-click="goto"
                 :scrollable="true"
                 scroll-height="flex"
                 class="p-datatable-sm">

        <Column field="name" header="Name" :sortable="true" class="cursor-pointer"></Column>
        <Column field="therapist" header="TherapeutIn" :sortable="true" class="cursor-pointer"></Column>
        <Column field="type" header="Typ" :sortable="true" class="cursor-pointer"></Column>
      </DataTable>
    </div>

  </Dialog>
</template>

<script setup>

import Dialog from 'primevue/dialog'
import {watch} from 'vue'
import InputText from 'primevue/inputtext'
import {throttle} from 'lodash'
import axios from 'axios'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import {Inertia} from '@inertiajs/inertia'
import Button from 'primevue/button'

const props = defineProps({
  modelValue: Boolean,
})


let _term = $ref('')
let _results = $ref([])
let _searching = $ref(false)

let _visible = $ref(props.modelValue)
watch(() => props.modelValue, (value) => {
  _term = ''
  _visible = value
})

watch(() => _term, throttle(async () => {


  if (_term.length === 0) {
    _results = []
  }

  if (_term.length < 3) {
    return
  }

  _searching = true

  const response = await axios.get('/search', { params: { term: _term }})

  _results = response.data
  _searching = false

}, 300))

const emit = defineEmits(['update:modelValue'])

function goto(e) {

  _term = ''
  _visible = false

  emit('update:modelValue', false)

  window.location = e.data.url
}

</script>

<style lang="scss">

#search-modal {
  margin-top: 15vh;
}

.search-modal-content {
  overflow: hidden;
  border-radius: .33rem;
}

</style>
